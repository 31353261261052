@import "./variables.scss";
@import "../fonts/fonts.scss";


html {   
  background: url(../images/fondomobile.png) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

#root {
  text-align: center;
  width: 100%;
}

body {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0.1px;
  min-width: 400px;
  margin: 0;
}

a {
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.content {
  text-align: center;
  color: white;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.logo {
  margin: 2vh;
  img {
    max-width: 150px;
    max-height: 100%;
  }
}

.appstore {
  img {
    max-width: 50%;
    margin: 2em;
  }
}

.terms {
  font-size:10pt;
  div {
    font-size: 7pt;
  }
}

.text {
  font-family: 'Point-soft';
  margin: 2vh 2vw;
  h1 {
    font-weight: bold;
  }
  p {
    margin: 0 1em;
    text-align: center;
  }
}

.header {
  margin-top: 1em;
  margin-right: 10vw;
  text-align: right;
  select {
    background: none;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 12pt;
    overflow:hidden;
    option {
      background: none;
      color:black;
      
    }
  }
}

@media (max-width:640px) {
  img {
    max-width: 90%;
    width: -webkit-fill-available;
    height: auto;
  }
}
@media (min-width: 640px) {
  .logo {
    img {
      width: 50%;
    }
  }
  .text {
    p {
      margin: 0 auto;
      width: 80%;
    }
  }
}

@media (min-width: 768px) {
  .content{
    max-width: 80vw;
  }
  .logo {
    img {
      width: 250px;
    }
  }
}

@media (min-width: 1200px) {
  .logo {
    img {
      width: 150px;
    }
  }
  .text {
    p {
      margin: 0 auto;
      width: 30vw;
    }
  }
}
