@font-face {
  font-family: 'Comfortaa';
  src: url('Comfortaa-Light.woff2') format('woff2'),
      url('Comfortaa-Light.woff') format('woff'),
      url('Comfortaa-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('Comfortaa-Regular.woff2') format('woff2'),
      url('Comfortaa-Regular.woff') format('woff'),
      url('Comfortaa-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('Comfortaa-Bold.woff2') format('woff2'),
      url('Comfortaa-Bold.woff') format('woff'),
      url('Comfortaa-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Point-Soft';
  src: url('point-soft-black.ttf') format('truetype')
}
